@import 'scss/variables';

.steps {
    width: auto;
    margin: 0 auto;

    :global {
        .ant-steps-item-description {
            display: block !important;
        }
    }
}

.selector {
    margin: 0 auto;
}

.column {
    background-color: $gray;
}

.columnDark {
    :global {
        .ant-card-head {
            border-bottom: none;
        }

        .agenda-item {
            background-color: #22272B;  // Trello colors
            border: none;
        }
    }
}

.agendaItem {
    cursor: pointer;
}
