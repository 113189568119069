.steps {
    :global {
        .ant-steps {
            background: var(--gray);
            padding: 8px 20px;
            border-radius: 10px;
            border: 1px solid var(--border);
            flex: 0 0 220px;
            width: 200px;
            .ant-steps-item-title {
                text-wrap: nowrap;
                color: var(--dark);
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}
.stepsContent {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stepsContentBody {
    flex: 1;
}

.stepsContentFooter {
    gap: 10px;
    :global {
        .ant-btn {
            padding: 14px 25px;
            text-transform: uppercase;
            height: auto;
            width: auto;
            line-height: 1;
            font-weight: 600;
            font-size: 15px;
        }
    }
}

.modal {
    .ant-modal-content {
        border-radius: 10px;
        padding: 0;
        border: 1px solid var(--border);
        background-color: var(--bg-alt);
    }
    .ant-modal-header {
        padding: 15px 20px;
        border-bottom: 1px solid var(--border);
        background-color: var(--bg-alt);
    }
    .ant-modal-body {
        padding: 20px;
    }
}
