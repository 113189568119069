.table {
    :global {
        .ant-table {
            margin-block: 0 !important;
            .ant-table-cell:first-child {
                padding-right: 0;
            }
            .ant-table-cell {
                &:nth-child(2) {
                    min-width: 20%;
                    width: 20%;
                    max-width: 20%;
                }
                &:last-child {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                }
                .ant-table-cell {
                    &:nth-child(2) {
                        min-width: 18.9%;
                        width: 18.9%;
                        max-width: 18.9%;
                    }   
                    .ant-table-cell {
                        &:nth-child(2) {
                            min-width: 17.7%;
                            width: 17.7%;
                            max-width: 17.7%;
                        }   
                    }
                }
            }
            .ant-table-expanded-row {
                .ant-table-container {
                    .ant-table-thead {
                        display: none;
                        th {
                            background-color: var(--gray) !important;
                            border-radius: 0 !important;
                        }
                    }
                }
                table {
                    .ant-table-tbody {
                        tr {
                            .ant-table-cell {
                                //border: none;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-row-expand-icon {
            border: none;
            width: 20px;
            height: 20px;
            &::before,
            &::after {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
            &::before {
                width: 12px;
                height: 2px;
            }
            &::after {
                height: 12px;
                width: 2px;
            }
        }
    
    
    
        table {
            .ant-table-tbody {
                tr {
                    &.ant-table-expanded-row {
                        > .ant-table-cell {
                            padding: 0;
                        }
                    }
                }
            }
        }
    
        .ant-table-expanded-row {
            .ant-table {
                border: none;
                border-radius: 0 !important;
                margin-inline: 0 !important;
                border-left: 1px dashed var(--border);
                margin-left: 20px !important;
            }
        }
        
    }
}
