.dd {
  h3 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  /*
  & > div > p:first-of-type {
    margin-top: 14px;
  }
  */

  margin-bottom: 1.5em;
}
