.tooltip {
    position: absolute;
    text-align: center;
    background-color: white;
    color: black;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 8px;

    &:after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }

    :global {
    }
}

.dark {
    color: white;
    background-color: #333639;

    &:after {
        border-color: #333639 transparent transparent transparent;
    }
}
