.table {
    :global {
        tr .ant-btn {
            visibility: hidden;
        }

        tr:hover .ant-btn {
            visibility: visible;
        }
    }
}

.buttons {
    white-space: nowrap;
}
