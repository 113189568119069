.switches {
    padding: 15px 27px;
}

.switch {
    &:not(:last-child) {
        margin-bottom: .5em;
    }
    display: flex;
    gap: .25em;
    align-items: center;
}
