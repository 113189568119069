.card {
  :global {
    .title {
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
