.layout {
    background-color: var(--body-bg);
    :global {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .v-org-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 17px 50px;
            border-bottom: 1px solid var(--input-border);
            .logo {
                display: flex;
                img {
                    height: 40px;
                }
            }

            .org-h-end {
                gap: 20px;
            }

            .h-user-dropdown {
                align-items: center;
                gap: 12px;
                cursor: pointer;
                .h-username {
                    position: relative;
                    padding-right: 20px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 6px;
                        right: 0;
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid currentColor;
                        border-right: 2px solid currentColor;
                        transform: rotate(135deg);
                    }
                }
            }
        }

        .v-org-wrap {
            flex: 1;
            overflow-y: auto;
            padding: 20px;
        }

        h2.ant-typography {
            text-align: center;
            margin: 0;
            padding: 40px 0;
            font-size: 45px;
            font-weight: 700;
        }

        .org-card {
            padding: 30px 20px;
            border-radius: 16px;
            transition: ease all 0.35s;
            box-shadow: 0 0 0 2px transparent;
            cursor: pointer;
            &:hover {
                border: 1px solid var(--primary);
                box-shadow: 0 0 0 2px var(--primary);
            }
            .ant-card-body {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 0;
            }
            h3 {
                margin: 0;
                padding: 20px 0 0;
                font-size: 20px;
            }
        }
    }
}
