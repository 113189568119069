.tab {
    :global {
        .ant-row {
            .ant-col {
                display: flex;
                margin: 0.5rem 0;
                > .ant-typography {
                    height: 100%;
                    width: 100%;
                    display: flex;
                }
            }
        }

        .FixTabBtn {
            position: absolute;
            top: -40px;
            right: 10px;
        }
    }
}

.card {
    border: 1px solid var(--border);
    width: 100%;
    padding: 1rem;
    :global {
        .avatar {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            background-color: rgba(var(--primary-rgb), 0.15);
            color: var(--primary);
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            border-radius: 50%;
        }
        .card-info {
            padding-left: 1rem;
            padding-right: 1rem;
            flex: 1;
        }
        .designation {
            color: var(--text-color);
            font-size: 0.85rem;
        }
        .ant-card-body {
            padding: 0;
        }
        .link-icon {
            position: absolute;
            top: 8px;
            right: 10px;
            padding: 0;
            border: none;
            width: 24px;
            height: 24px;
        }
        h3 {
            font-size: 1em;
            margin: 0;
            color: var(--dark);
        }
        .status {
            display: flex;
            align-items: center;
            gap: 8px;
            i {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--primary);
            }
            span {
                font-size: 0.85rem;
                font-weight: 500;
                color: var(--primary);
            }
        }
        .checkbox {
            display: flex;
            margin-left: auto;
            margin-right: -16px;
            border: 1px solid var(--dark);
            border-radius: 3px;
            padding: 0;
            width: 16px;
            height: 16px;
            align-items: center;
            justify-content: center;
            svg {
                width: 10px;
                fill: var(--dark);
            }
        }
    }
}
