.table {
    :global {
        .ant-table, .ant-table-cell {
            border-radius: 0 !important;
            border-end-start-radius: 0 !important;
            border-end-end-radius: 0 !important;
        }
    }
}

.button {
    margin-top: .5em;
}
