@import 'scss/variables';
$image_size: 48px;
$font_size: 16px;

.chart {
    max-height: 100%;
    :global {
        .node-foreign-object-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: 1px solid $primary;
            gap: 8px;

            .circle {
                width: $image_size;
                height: $image_size;
                line-height: $image_size;
                border-radius: 50%;
                font-size: $font_size;
                color: #fff;
                text-align: center;
                background: #000
            }

            .img {
                width: $image_size;
                height: $image_size;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: $image_size;
                    height: $image_size;
                }
            }

            .name {
                font-size: 16px;
                font-weight: bold;
            }
            .title {
                font-size: 12px;
            }
        }
    }
}
