@import 'scss/variables';

.page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    :global {
        .auth-container {
            max-width: 400px;
            width: 100%;
            padding: 20px;
        }
        .login-logo {
            display: flex;
            justify-content: center;
            padding: 0 0 20px;
            img {
                width: auto;
                height: 65px;
            }
        }
        h2 {
            margin: 0;
            padding: 0 0 30px;
            font-weight: 700;
            text-align: center;
        }
        .ant-input-outlined {
            padding: var(--input-padding-y) var(--input-padding-x);
            font-weight: 400;
            font-size: var(--input-font);
            line-height: 100%;
            .ant-input-prefix {
                margin-right: 10px;
            }
        }
        .ant-btn {
            padding: var(--btn-padding-y) var(--btn-padding-x);
            height: auto;
            border-radius: var(--btn-rounded);
            font-size: var(--btn-font);
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
        }
        .login-help-text {
            text-align: center;
            color: #676F7E;
            font-size: 16px;
            font-weight: 400;
            a {
                color: var(--primary);
                text-decoration: none;
            }
        }
        .reset-link {
            display: inline-block;
            text-align: center;
            padding: 0 12px;
            font-size: 16px;
            border-radius: 10px;
            span {
                color: var(--primary);
                font-weight: 500;
            }
        }
    }
}
