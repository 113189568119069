@import "scss/variables";

.card {
    :global {
        .ant-card-meta {
            margin: 0 0 1em 0;
        }
    }
}

.people {
    :global {
        .ant-descriptions-item-label {
            white-space: nowrap;
            display: table-cell;
            width: 1%;            /* Ensures it takes only as much space as the content */
            vertical-align: top;
            font-weight: bold;
        }
    }
}

:export {
    danger: $danger;
}
