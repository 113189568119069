.table {
    background-color: transparent;

    :global {
        .ant-table-content {
            table {
                .ant-table-tbody {
                    tr {
                        .ant-table-cell {
                            border-bottom: 1px solid var(--border);
                            border-radius: 0 !important;
                            color: var(--dark);
                            font-weight: 500;
                            padding: 12px 12px;

                            &:last-child {
                                text-align: right;
                            }
                        }

                        &:last-child {
                            .ant-table-cell {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
