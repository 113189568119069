.form-control {
    padding-bottom: 0;
    margin-bottom: 12px;

    > label {
        padding-bottom: 8px;
        display: inline-block;
        vertical-align: top;
    }
    .ant-select {
        width: 100%;
        height: 45px;
    }

    .ant-form-item-control-input {
        min-height: 45px;
    }

    .ant-input {
        padding: 10px 12px;
    }

    .ant-form-item-row {
        flex-direction: column;
    }

    .ant-form-item-control {
        min-height: auto;
    }

    .ant-form-item-label {
        text-align: left;
    }

    &.ant-form-item-with-help {
        margin-bottom: 24px;
    }
}
