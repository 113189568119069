.form {
    :global {
        .ant-table-content {
            .ant-table-row {
                td:last-child {
                    text-align: right;
                }
            }
            .ant-typography {
                padding: 2px 10px;
                border: 1px solid #01bd50;
                border-radius: 20px;
                font-size: 11px;
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    background-color: #01bd50;
                    color: #fff;
                }
            }
            .ant-typography-danger {
                border: 1px solid #ff4d4f;
                &:hover {
                    background-color: #ff4d4f;
                    color: #fff;
                }
            }
        }
    }
}
