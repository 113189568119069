.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%
}

.canvas {
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    border: 0;
    touch-action: none;  // https://use-gesture.netlify.app/docs/extras/#touch-action
}
