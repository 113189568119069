.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    border-bottom: 1px solid var(--input-border);

    :global {
        h1 {
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: 600;
            color: var(--dark);
        }
    }
}

.popover {
    :global {
        svg {
            width: 28px;
        }
    }
}

.button {
    background: transparent;

    :global {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.profile {
    cursor: pointer;
}
